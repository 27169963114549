/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
// L'API du navigateur Gatsby vous offre de nombreuses options pour interagir avec le côté client de Gatsby.
// https://openbase.com/js/simple-react-lightbox/documentation

import React from 'react';
import SimpleReactLightbox from 'simple-react-lightbox';
// USE THE IMPORT BELOW INSTEAD IF YOU ARE USING THE PRO VERSION
// import SimpleReactLightbox from 'simple-react-lightbox-pro'


export const wrapRootElement = ({ element}) => (
    <SimpleReactLightbox>{element}</SimpleReactLightbox>
)

